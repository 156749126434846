const setSessionStore = (key, value) => {
	sessionStorage.setItem(key, JSON.stringify(value || key));
}
const getSessionStore = (key) => {
	return sessionStorage.getItem(key)?.replaceAll('"', '');
}
const sessionStoreClear = () => {
	sessionStorage.clear();
}

export { setSessionStore, getSessionStore, sessionStoreClear };