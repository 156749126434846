import { FAKE_AUTH, MOCK_NAME, URLS } from '../constants/env';
import { refreshToken } from './auth-handlers';
import { getSessionStore } from './session-store';
import { alertUser } from '../utils/alert';

export const abortController = new AbortController();

const fetchUrl = async (url, method = 'GET', body = null, ignoreMessageCodes= [], ignoreBearer = false) => {
	const getHeaders = () => {
		const headers = {
			'Accept-Language': localStorage.getItem('language') || 'de'
		};
		if (!ignoreBearer && !FAKE_AUTH) {
			const token = getSessionStore(`access_token-${MOCK_NAME}`);
			headers['Authorization'] = `Bearer ${token}`;
		}
		if (body) {
			body instanceof FormData ? delete headers['Content-Type'] : headers['Content-Type'] = 'application/json';
		}
		return headers;
	};

	const getBody = () => {
		return body instanceof FormData ? body : JSON.stringify(body);
	}

	try {
		const options = {
			method,
			headers: getHeaders(),
			body: body && getBody(),
			signal: abortController.signal
		};

		let response = await fetch(URLS.api + url, options);

		if (response.status === 401) {
			console.warn('Access token expired');
			await refreshToken();
			response = await fetch(URLS.api + url, { ...options, headers: getHeaders() });
		}

		const message = await getResponseMsg(response);

		if (message && !ignoreMessageCodes.includes(response.status)) {
			alertUser(message, 'error');
		}

		return response;
	} catch (error) {
		throw new Error(error);
	}
}

export const getResponseMsg = async (response) => {
	if (response.headers?.get('content-type')?.includes('application/json')) {
		const data = await response.clone().json();
		const lang = localStorage.getItem('language') || 'de';

		if (data.message) {
			console.info('Response message: ' + data.message[lang] || data.message['en']);
			return data.message[lang] || data.message['en'];
		}
	}
}

export const getAsync = async (url, ignoreMessageCodes = [], ignoreBearer = false) => {
	return await fetchUrl(url, 'GET', null, ignoreMessageCodes, ignoreBearer);
}

export const postAsync = async (url, body, ignoreMessageCodes = [], ignoreBearer = false) => {
	return await fetchUrl(url, 'POST', body, ignoreMessageCodes, ignoreBearer);
}

export const putAsync = async (url, body, ignoreMessageCodes = [], ignoreBearer = false) => {
	return await fetchUrl(url, 'PUT', body, ignoreMessageCodes, ignoreBearer);
}

export const deleteAsync = async (url, ignoreMessageCodes = [], ignoreBearer = false) => {
	return await fetchUrl(url, 'DELETE', null, ignoreMessageCodes, ignoreBearer);
}

export const backendCourseCode = (course) => course.replaceAll('-', '_');
const dtoCode = (course) => course.replace(/-([a-z])/g, (match, group1) => group1.toUpperCase());
export const dtoCourseCode = (course) => dtoCode(course) + 'Score';
export const dtoDateCode = (course) => dtoCode(course) + 'Date';
