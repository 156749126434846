import { APP_VERSIONS } from '../constants/env';

export function dateFormatter(str) {
	return str
		? new Date(str).toLocaleDateString('de-DE', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		})
		: '';
}

export const versionsFormater = () => {
	if (APP_VERSIONS.startsWith('Build time')) {
		return APP_VERSIONS;
	}

	const parts = APP_VERSIONS.split(',').map(part => part.trim());
	if (parts.length !== 5) {
		return 'Invalid versions format';
	}

	const [ mvpVersion, mockFEVersion, feVersion, mockBEVersion, beVersion ] = parts;
	return `${mvpVersion}, FE: ${mockFEVersion} (UNI_react_${feVersion}), BE: ${mockBEVersion} (UNI_be_${beVersion})`;
};