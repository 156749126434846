import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { MainLayout } from './main/layout';
import { HomePage } from '../pages/home';
import { ErrorPage } from 'common-frontend/components/error';
import { UserLayout } from './user/layout';
import { UserPage } from '../pages/user';
import { DocumentsPage } from '../pages/documents';
import { AdmissionPage } from '../pages/admission';
import { PATHS } from '../../constants/paths';

export const Router = () => {
	return (
		<Routes>
			<Route element={ <MainLayout/> }>
				<Route path={ PATHS.home } element={ <HomePage/> } errorElement={ <ErrorPage/> }/>
			</Route>
			<Route element={ <UserLayout/> }>
				<Route path={ PATHS.user } element={ <UserPage/> }/>
				<Route path={ PATHS.admission } element={ <AdmissionPage/> }/>
				<Route path={ PATHS.documents } element={ <DocumentsPage /> }/>
			</Route>
			<Route path="*" element={ <ErrorPage message={'404 page not found'}/> } />
		</Routes>
	);
};