import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { UPLOAD_DOCUMENT } from '../services/api-calls';

export const FileUploader = observer(({ buttonClassName, handleUpload }) => {
	const { t } = useTranslation();

	const [ uploadLoader, setUploadLoader ] = useState(true);
	const [ uploadPopupIsOpen, setUploadPopupIsOpen ] = useState(false);
	const [ uploadingFile, setUploadingFile ] = useState(null);

	const uploadFileHandler = useCallback(() => {
		setUploadLoader(true);

		UPLOAD_DOCUMENT(uploadingFile).finally(() => {
			setUploadPopupIsOpen(false);
			setUploadLoader(false);
			setUploadingFile(null);
			handleUpload();
		});
	}, [ uploadingFile, handleUpload ]);

	useEffect(() => {
		!uploadingFile ? setUploadLoader(true) : setUploadLoader(false);
	}, [ uploadingFile ]);

	return(
		<>
			<Button id="upload-popup-button" variant="contained" onClick={ () => setUploadPopupIsOpen(true) } className={buttonClassName}>
				{ t('files.button-upload-popup') }
			</Button>
			<Dialog id="upload-document-popup" open={ uploadPopupIsOpen } onClose={ () => setUploadPopupIsOpen(false) }>
				<Box width="400px" padding="16px">
					<Uploader t={t} uploadingFile={ uploadingFile } setUploadingFile={ setUploadingFile }/>
					<Button id="upload-document-button" variant="contained" onClick={ uploadFileHandler } disabled={ uploadLoader } fullWidth>
						{ t('files.button-upload-document') }
					</Button>
				</Box>
			</Dialog>
		</>
	);
});

const Uploader = React.memo(({ t, uploadingFile, setUploadingFile }) => {
	const [ error, setError ] = useState('');

	const handleFileChange = (e) => {
		const selectedFile = e.target.files[0];
		if (selectedFile) {
			if (selectedFile.type !== 'application/pdf') {
				setError(t('alerts.only-pdf'));
				return;
			}
			if (selectedFile.size > 10 * 1024 * 1024) {
				setError(t('alerts.file-max-size'));
				return;
			}
			if (selectedFile.name.length > 100) {
				setError(t('alerts.file-max-name-length'));
				return;
			}
			setError('');
			setUploadingFile(selectedFile);
		}
	};

	return (
		<Grid container justifyContent="space-between" className="mb-16">
			{!uploadingFile ? (
				<>
					<Button id="input-file-button" startIcon={<UploadIcon/>} component="label" variant="outlined" fullWidth>
						{ t('files.button-input-file') }
						<input id="input-file" type="file" accept="*/pdf,.pdf" onChange={handleFileChange} data-testid="file-input" />
					</Button>
					<Typography color="error">{error}</Typography>
				</>
			) : (
				<>
					<div>
						<p><b>{t('files.upload.file-name')}:</b> {uploadingFile.name}</p>
						<p><b>{t('files.upload.size')}:</b> {(uploadingFile.size / 1024 / 1024).toFixed(2)} MB</p>
					</div>
					<IconButton aria-label="delete" color="error" onClick={() => setUploadingFile(null)} data-testid="delete-file-button">
						<DeleteOutlineIcon sx={{ fontSize: 20 }} />
					</IconButton>
				</>
			)}
		</Grid>
	);
});
