import { CLIENT_ID, FAKE_AUTH, MOCK_NAME, URLS } from '../constants/env';
import { getSessionStore, setSessionStore, sessionStoreClear } from './session-store';
import { abortController, getResponseMsg } from './fetch-helper';
import { STORES } from '../services/shared-stores';

const signIn = () => {
	if (!FAKE_AUTH) {
		console.info('Signing in: getting state and code');
		sessionStoreClear();
		location.href = `${URLS.idp}/protocol/openid-connect/auth?client_id=${CLIENT_ID}&redirect_uri=${URLS.app}&response_type=code&scope=openid`;
		return;
	}

	/**
	 * Use only if AAI is not available
	 */
	console.info('Signing in: without AAI');
	const { AuthStore } = STORES;
	setSessionStore(`access_token-${MOCK_NAME}`);
	setSessionStore(`preferred_username-${MOCK_NAME}`);
	setSessionStore(`id_token-${MOCK_NAME}`);
	setSessionStore(`sub-${MOCK_NAME}`);

	AuthStore.setAuthProfile({
		sub: `sub-${MOCK_NAME}`,
		preferred_username: `preferred_username-${MOCK_NAME}`,
	});
}

const signOut = () => {
	if (!FAKE_AUTH) {
		const token = getSessionStore(`id_token-${MOCK_NAME}`);
		sessionStoreClear();
		location.href = `${URLS.idp}/protocol/openid-connect/logout?id_token_hint=${token}&post_logout_redirect_uri=${URLS.app}`;
		return;
	}

	/**
	 * Use only if AAI is not available
	 */
	sessionStoreClear();
	location.reload();
}

const refreshToken = async () => {
	const refreshResult = await fetch(
		`${URLS.api}/token/refresh-token`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ refreshToken: getSessionStore(`refresh_token-${MOCK_NAME}`) })
		}
	);

	if (refreshResult.status === 400) {
		const msg = await getResponseMsg(refreshResult);
		localStorage.setItem('post-logout-message', `${msg}|error`);
		abortController.abort();
		signOut();
		return;
	}

	const data = await refreshResult.json();
	setSessionStore(`access_token-${MOCK_NAME}`, data.access_token);
	setSessionStore(`refresh_token-${MOCK_NAME}`, data.refresh_token);
	console.info('Access token refreshed');
}

const signOutTracker = (min = 15) => {
	let lastActivity =  new Date().getTime();
	let timeout = 0;

	const mouseMoveHandler = () => {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			lastActivity = new Date().getTime();
		}, 500);
	};

	document.body.addEventListener('mousemove', mouseMoveHandler);

	setInterval(() => {
		if (lastActivity + min * 60 * 1000 < new Date().getTime()) {
			console.info('Auto logout because user not active');
			signOut();
			document.body.removeEventListener('mousemove', mouseMoveHandler);
		}
	}, 60 * 1000);
}

export { signIn, signOut, signOutTracker, refreshToken };