import React from 'react';
import { Pagination, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Skeleton, Button, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { dateFormatter } from 'common-frontend/utils/formatters';
import RefreshIcon from '@mui/icons-material/Refresh';
import { PAGE_SIZE, useDocuments, useMutateDocument } from '../../utils/documents';
import { useToggle } from 'common-frontend/utils/hooks';
import { FileUploader } from 'common-frontend/components/file-uploader';
import { AcceptPopup } from 'common-frontend/components/accept-popup';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

export const InboundDocumentsTable = observer(() => {
	const { t } = useTranslation();
	const { documents, currentPage, pagesCount, loading, handleDownload, handlePageChange, refetch } = useDocuments('inbound');

	return (
		<TableWrapper loading={loading} id="inbound" refetch={refetch}>
			<TableContainer className="table">
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{t('files.filename')}</TableCell>
							<TableCell width="15%">{t('files.dna-status')}</TableCell>
							<TableCell width="15%">{t('files.verification-status')}</TableCell>
							<TableCell width="20%">{t('files.created-at')}</TableCell>
							<TableCell align="center" width="10%">{t('files.download')}</TableCell>
							<TableCell align="center" width="10%">{t('files.actions')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<BodyWrapper documents={documents} loading={loading} columnsCount={6} t={t}>
							{documents.map(document => (
								<InboundDocumentsRow
									key={document.uuid}
									document={document}
									t={t}
									handleDownload={handleDownload}
								/>
							))}
						</BodyWrapper>
					</TableBody>
				</Table>
			</TableContainer>
			<Pagination count={ pagesCount } page={ currentPage } color="primary" onChange={ handlePageChange } />
		</TableWrapper>
	);
});

const InboundDocumentsRow = ({ document, t, handleDownload }) => {
	const { verifyDocument, verifying } = useMutateDocument('outbound', document.uuid);

	const verificationStatus = document.verificationStatus ? t(`files.verification-statuses.${document.verificationStatus}`) : '';
	const isVerified = document.verificationStatus === 'VERIFIED';

	const getActionComponent = () => {
		if (document.dnaStatus !== 'SIGNED') {
			return null;
		}

		return (
			<LoadingButton id={`verify-button-${document.uuid + 1}`} loading={verifying} disabled={isVerified} onClick={verifyDocument}>
				{t(isVerified ? 'files.verified-button' : 'files.verify-button')}
			</LoadingButton>
		);
	};

	return (
		<TableRow key={document.uuid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell>{document.title || document.filename}</TableCell>
			<TableCell>{document.dnaStatus ? t(`files.dna-statuses.${ document.dnaStatus }`) : ''}</TableCell>
			<TableCell>{verifying ? <CircularProgress size={24} /> : verificationStatus}</TableCell>
			<TableCell>{dateFormatter(document.createdAt)}</TableCell>
			<TableCell align="center">
				<Link className="download-document" to='#download' id={`download-inbound-documents-button-${document.uuid + 1}`} onClick={() => { handleDownload(document); }} title={t('files.download')}>
					<FileDownloadIcon/>
				</Link>
			</TableCell>
			<TableCell align="center">
				{getActionComponent()}
			</TableCell>
		</TableRow>
	);
};

export const OutboundDocumentsTable = observer(() => {
	const { t } = useTranslation();
	const { documents, currentPage, pagesCount, loading, handleDownload, handlePageChange, refetch } = useDocuments('outbound');

	return (
		<>
			<TableWrapper loading={loading} id="outbound" refetch={refetch}>
				<TableContainer className="table">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>{t('files.filename')}</TableCell>
								<TableCell width="15%">{t('files.dna-status')}</TableCell>
								<TableCell width="15%">{t('files.verification-status')}</TableCell>
								<TableCell width="15%">{t('files.upload-date')}</TableCell>
								<TableCell width="15%">{t('files.last-sent-date')}</TableCell>
								<TableCell align="center" width="10%">{t('files.download')}</TableCell>
								<TableCell align="center" width="10%">{t('files.actions')}</TableCell>
								<TableCell align="center" width="10%">{t('files.send-to-wallet')}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<BodyWrapper documents={documents} loading={loading} columnsCount={8} t={t}>
								{documents.map(document =>
									<OutboundDocumentsRow
										key={document.uuid}
										document={document}
										t={t}
										handleDownload={handleDownload}
									/>
								)}
							</BodyWrapper>
						</TableBody>
					</Table>
				</TableContainer>
				<Pagination count={ pagesCount } page={ currentPage } color="primary" onChange={ handlePageChange } />
			</TableWrapper>
			<FileUploader buttonClassName="mt-32" handleUpload={refetch} />
		</>
	);
});

const OutboundDocumentsRow = ({ document, t, handleDownload }) => {
	const {
		signDocument, signing,
		verifyDocument, verifying,
		revokeDocument, revoking,
		sendDocument, sending
	} = useMutateDocument('outbound', document.uuid);
	const [ revokePopup, toggleRevokePopup ] = useToggle(false);

	const dnaStatus = document.dnaStatus ? t(`files.dna-statuses.${document.dnaStatus}`) : '';
	const verificationStatus = document.verificationStatus ? t(`files.verification-statuses.${document.verificationStatus}`) : '';
	const isSent = !!document.lastSentDate;

	const getActionComponent = () => {
		if (document.dnaStatus !== 'SIGNED') {
			return (
				<LoadingButton fullWidth id={`sign-button-${document.uuid}`} loading={signing} onClick={signDocument}>
					{t('files.sign-button')}
				</LoadingButton>
			);
		}

		if (document.verificationStatus !== 'VERIFIED') {
			return (
				<LoadingButton fullWidth id={`verify-button-${document.uuid}`} loading={verifying} onClick={verifyDocument}>
					{t('files.verify-button')}
				</LoadingButton>
			);
		}

		return (
			<>
				<LoadingButton fullWidth id={`revoke-button-${document.uuid}`} loading={revoking} onClick={toggleRevokePopup}>
					{t('files.revoke-button')}
				</LoadingButton>
				<AcceptPopup
					id='revoke-document'
					title={t('revoke-document.title')}
					text={t('revoke-document.dialog')}
					isOpen={revokePopup}
					isDanger
					onClose={toggleRevokePopup}
					action={() => {
						revokeDocument();
						toggleRevokePopup();
					}}
					closeIcon
				/>
			</>
		);
	};

	return (
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell>{document.title || document.filename}</TableCell>
			{(signing || revoking) ? <CellSkeleton /> : <TableCell>{dnaStatus}</TableCell>}
			{(signing || verifying || revoking) ? <CellSkeleton /> : <TableCell>{verificationStatus}</TableCell>}
			<TableCell>{dateFormatter(document.uploadDate)}</TableCell>
			{((signing || verifying || revoking) && isSent && document.lastSentDate) ? <CellSkeleton /> : <TableCell>{dateFormatter(document.lastSentDate)}</TableCell>}
			<TableCell align="center">
				<Link to='#download'className="download-document" id={`download-outbound-documents-button-${document.uuid}`} onClick={() => handleDownload(document)} title={t('files.download')}>
					<FileDownloadIcon/>
				</Link>
			</TableCell>
			<TableCell align="center">
				{getActionComponent()}
			</TableCell>
			{((signing || verifying || revoking) && isSent)
				? <CellSkeleton />
				: (
					<TableCell align="center">
						<LoadingButton id={`send-to-wallet-button-${document.uuid}`} loading={sending} disabled={isSent} onClick={sendDocument}>
							{t(isSent ? 'files.sent-button' : 'files.send-to-wallet-button')}
						</LoadingButton>
					</TableCell>
				)
			}
		</TableRow>
	);
};

const TableWrapper = ({ children, loading, id, refetch }) => {
	return (
		<>
			<Button id={`refresh-documents-button-${id}`} className="refresh-button mb-24" variant="contained" disabled={loading} onClick={refetch}>
				<RefreshIcon />
			</Button>
			{children}
		</>
	);
};

const BodyWrapper = ({ children, documents, loading, columnsCount, t }) => {
	const bodyLayout = documents.length === 0
		? <TableRow><TableCell align="center" colSpan={columnsCount}>{t('files.empty')}</TableCell></TableRow>
		: children;

	return loading ? <RowsSkeleton columnsCount={columnsCount} /> : bodyLayout;
};

const RowsSkeleton = ({ columnsCount }) => {
	function* generateNumbers(n) {
		for (let i = 1; i <= n; i++) {
			yield i;
		}
	}

	return [ ...generateNumbers(Math.min(5, PAGE_SIZE)) ].map((i) => (
		<TableRow key={i}>
			{[ ...generateNumbers(columnsCount) ].map((j) => <CellSkeleton key={j} />)}
		</TableRow>
	));
};

const CellSkeleton = () => (
	<TableCell component="td" scope="row">
		<Skeleton animation="wave" variant="text" />
	</TableCell>
);